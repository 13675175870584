<script lang="ts" setup>
import { contactData } from "~/state/state";

const contactList = contactData.toArray;
</script>
<template>
    <VRow>
        <VCol v-for="(item, i) in contactList" :key="i" cols="12" sm="6" md="4" lg="12" class="px-md-0 px-lg-3">
            <ContactItem :icon="item.icon" :title="item.title" :text="item.text" :url="item.url" :color="item.color.dark" :target="item.target" />
        </VCol>
    </VRow>
</template>
