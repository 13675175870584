<script lang="ts" setup></script>
<template>
    <SectionWrapper>
        <DecoratedTitle
            text="Get in touch with us"
            decorated-phrase="touch with us"
            append-icon="star-2"
            append-icon-size=".6em"
            append-icon-position="120% -70%"
            append-icon-animation-class="pulse"
            class="text-center"
        />
        <VRow align="start" align-lg="center" class="contact-wrapper">
            <VCol cols="12" lg="4" xl="5">
                <ContactList />
            </VCol>
            <VCol cols="12" lg="8" xl="7">
                <ContactForm />
            </VCol>
        </VRow>
    </SectionWrapper>
</template>
<style lang="scss">
.contact-wrapper {
    margin-top: 1rem;

    @media screen and (min-width: 600px) {
        margin-top: 1.25rem;
    }

    @media screen and (min-width: 960px) {
        margin-top: 2.5rem;
    }

    @media screen and (min-width: 1280px) {
        margin-top: 3.75rem;
    }

    @media screen and (min-width: 1920px) {
        margin-top: 6.25rem;
    }
}
</style>
