<script lang="ts" setup>
import { isValidPhoneNumber } from "libphonenumber-js";
import type { PropType } from "vue";
import type { VAutocomplete } from "vuetify/components";

const props = defineProps({
    modelValue: { type: String, default: "" },
    color: { type: String, default: "" },
    rounded: Boolean,
    required: Boolean,
    rules: { type: Array as PropType<VAutocomplete["rules"]>, default: () => [] },
    hideDetails: { type: [Boolean, String] as PropType<boolean | "auto" | undefined>, default: "auto" },
    density: { type: String as PropType<VAutocomplete["density"]>, default: "default" },
    variant: { type: String as PropType<VAutocomplete["variant"]>, default: undefined }
});

const emit = defineEmits(["update:modelValue"]);

const phoneRules = ref([...props.rules, (v: any) => !v || phoneNumberIsValid.value || "Incorrect phone number!"]);
const prefixRules = ref([(v: any) => !v || v.length < 4 || ""]);
const errorMessage = computed(() => {
    if (!phone.code.length && !phone.number.length) return "Fields is required!";
    else if (!phoneNumberIsValid.value) return "Incorrect phone number!";
});

const phone = reactive({
    code: "",
    number: ""
});

const phoneNumberIsValid = ref(true);

function OnChange() {
    phoneNumberIsValid.value = isValidPhoneNumber("+" + phone.code + phone.number);
    emit("update:modelValue", "+" + phone.code + phone.number);
}
</script>
<template>
    <v-row no-gutters>
        <v-col cols="auto" class="pr-5">
            <VTextField
                v-model.trim="phone.code"
                :density="density"
                :color="color"
                :variant="variant"
                :required="required"
                :rules="prefixRules"
                label="Prefix"
                style="max-width: 7em"
                prepend-inner-icon="$plus"
                name="tel-country-code"
                autocomplete="tel-country-code"
                persistent-placeholder
                hide-details
                @input="OnChange"
            ></VTextField>
        </v-col>
        <v-col>
            <VTextField
                v-model.trim="phone.number"
                :density="density"
                :rules="phoneRules"
                :color="color"
                :variant="variant"
                :required="required"
                label="Phone number"
                persistent-placeholder
                hide-details
                @input="OnChange"
            />
        </v-col>
        <VCol cols="12" style="height: 1em" :class="['text-error text-caption pl-4 mt-1 custom-error', { 'hide-warning': phoneNumberIsValid }]">
            {{ errorMessage }}
        </VCol>
    </v-row>
</template>
<style lang="scss" scoped>
.custom-error {
    will-change: opacity, transform;
    opacity: 1;
    transition:
        0.5s ease-in-out opacity,
        0.4s ease-in-out transform;

    &.hide-warning {
        transition:
            0.2s ease-in-out opacity,
            0.4s ease-in-out transform;
        transform: translate(0, -2em);
        opacity: 0;
    }
}
</style>
