export interface FormInputs {
    name: string;
    surname: string;
    phoneNumber: string;
    email: string;
    content: string;
    regulationsAccepted: boolean;
    specializationName?: string;
    serviceName?: string;
}

export interface RequestBody {
    error: any;
    result: any;
    success: boolean;
    targetUrl: any;
    unAuthorizedRequest: boolean;
}
interface DataT extends FormInputs {
    destination: number;
}
const URL = "https://portal-api-management.azure-api.net/api/public/Contact/request";
const headers = {
    "Content-Type": "application/json"
};

export async function sendEmail(formData: FormInputs) {
    const data = { ...formData };

    const header = `<b>${data.serviceName}</b>` + `${data.serviceName ? `<b> - ${data.serviceName}</b>` : ""}`;
    data.content = header + "<br><br>" + data.content;

    const { pending, status } = await useFetch<DataT>(URL, {
        method: "POST",
        body: {
            ...data,
            destination: 2
        },
        headers
    });

    return { pending, status };
}
