<script setup lang="ts">
import { useDisplay } from "vuetify";
import { specializationList } from "@/state/state";

const { mobile, smAndUp, mdAndUp } = useDisplay();
const validForm = ref(false);
const loading = ref(false);
const messageText = ref("");
const color = ref("");

const formInputs = reactive<FormInputs>({
    name: "",
    surname: "",
    email: "",
    phoneNumber: "",
    content: "",
    regulationsAccepted: false,
    specializationName: undefined,
    serviceName: undefined
});

const specializationId = ref<number | null>(null);
const specializationInputList = computed(() => specializationList.getElementsWithoutContent());
const serviceInputList = ref<unknown[]>([]);

watch(specializationId, () => {
    const specialization = specializationInputList.value.find((el) => el.id === specializationId.value);

    formInputs.specializationName = specialization?.name;
    serviceInputList.value = specialization?.servicesNames || [];
    formInputs.serviceName = "";
});
const rules = {
    isRequired: [(v: any) => !!v || "Field is required!"],
    email: [(v: any) => !!v || "Field is required!", (v: any) => /^[-.\w]+@([-\w]+\.)+[-\w]{2,4}$/gi.test(v) || "Incorrect email address!"]
};

async function onSubmit() {
    if (!validForm.value) return;
    loading.value = !loading.value;

    const { pending, status } = await sendEmail(formInputs);
    if (status.value === "success") {
        messageText.value = "Email sended successful.";
        color.value = "cyan";
    } else if (status.value === "error") {
        messageText.value = "We have some problem, try later.";
        color.value = "text-red";
    } else {
        messageText.value = "Sending...";
        color.value = "grey";
    }

    loading.value = pending.value;

    const timeout = setTimeout(() => {
        messageText.value = "";
        color.value = "";
        clearTimeout(timeout);
    }, 5000);
}
</script>

<template>
    <div class="form-wrapper">
        <VSheet rounded="lg" elevation="16" :loading="loading">
            <ClientOnly>
                <VForm v-model="validForm" @submit.prevent="onSubmit">
                    <VContainer class="pa-md-8">
                        <VRow>
                            <VCol cols="12" class="pt-0">
                                <h4 class="font-weight-regular">Choose a conversation topic (optional):</h4>
                            </VCol>
                            <VCol cols="12" md="6">
                                <VAutocomplete
                                    v-model.trim="specializationId"
                                    :items="specializationInputList"
                                    :density="mobile ? 'compact' : 'comfortable'"
                                    color="purple-dark"
                                    item-title="name"
                                    item-value="id"
                                    label="Choose specialization (optional)"
                                    persistent-placeholder
                                    variant="outlined"
                                    hide-details
                                    clearable
                                />
                            </VCol>
                            <VCol cols="12" md="6">
                                <VAutocomplete
                                    v-model.trim="formInputs.serviceName"
                                    :items="serviceInputList"
                                    :density="mobile ? 'compact' : 'comfortable'"
                                    :disabled="specializationId == null"
                                    item-title="name"
                                    item-value="name"
                                    color="purple-dark"
                                    label="Choose service (optional)"
                                    persistent-placeholder
                                    variant="outlined"
                                    hide-details
                                    clearable
                                />
                            </VCol>
                            <VCol cols="12">
                                <VDivider />
                            </VCol>
                            <VCol cols="12" class="pt-0">
                                <h4 class="font-weight-regular">Please fill out the contact form:</h4>
                            </VCol>
                            <VCol cols="12" md="6">
                                <VTextField
                                    v-model.trim="formInputs.name"
                                    :density="mobile ? 'compact' : 'comfortable'"
                                    :rules="rules.isRequired"
                                    color="purple-dark"
                                    label="First name"
                                    persistent-placeholder
                                    required
                                    variant="outlined"
                                    :hide-details="!mdAndUp && 'auto'"
                                />
                            </VCol>
                            <VCol cols="12" md="6">
                                <VTextField
                                    v-model.trim="formInputs.surname"
                                    :density="mobile ? 'compact' : 'comfortable'"
                                    :rules="rules.isRequired"
                                    color="purple-dark"
                                    label="Last name"
                                    persistent-placeholder
                                    required
                                    variant="outlined"
                                    :hide-details="!mdAndUp && 'auto'"
                                />
                            </VCol>

                            <VCol cols="12" md="6">
                                <VTextField
                                    v-model.trim="formInputs.email"
                                    :density="mobile ? 'compact' : 'comfortable'"
                                    :rules="rules.email"
                                    color="purple-dark"
                                    label="E-mail"
                                    persistent-placeholder
                                    required
                                    variant="outlined"
                                    :hide-details="!mdAndUp && 'auto'"
                                />
                            </VCol>

                            <VCol cols="12" md="6">
                                <ContactPhoneField
                                    v-model="formInputs.phoneNumber"
                                    :density="mobile ? 'compact' : 'comfortable'"
                                    :rules="rules.isRequired"
                                    color="purple-dark"
                                    label="Phone number"
                                    persistent-placeholder
                                    required
                                    variant="outlined"
                                    :hide-details="!mdAndUp && 'auto'"
                                />
                            </VCol>

                            <VCol cols="12">
                                <VTextarea
                                    v-model.trim="formInputs.content"
                                    :density="mobile ? 'compact' : 'comfortable'"
                                    :rules="rules.isRequired"
                                    auto-grow
                                    color="purple-dark"
                                    label="Message"
                                    persistent-placeholder
                                    required
                                    row-height="20"
                                    rows="4"
                                    variant="outlined"
                                    :hide-details="!mdAndUp && 'auto'"
                                />
                            </VCol>

                            <VCol cols="12">
                                <v-checkbox
                                    v-model.trim="formInputs.regulationsAccepted"
                                    :density="mobile ? 'compact' : 'comfortable'"
                                    :rules="rules.isRequired"
                                    color="purple-dark"
                                    hide-details
                                    label="I give permission to use the above data to contact me. (required)"
                                    required
                                >
                                    <template #label="{ label }">
                                        <span class="text-caption text-sm-body-2" v-text="label"></span>
                                    </template>
                                </v-checkbox>
                            </VCol>

                            <VCol cols="12">
                                <VRow no-gutters>
                                    <VCol sm="auto">
                                        <AccentBtn :style="{ width: !smAndUp ? '100%' : '' }" :disabled="loading" :loading="loading" type="submit"
                                            >Send</AccentBtn
                                        >
                                    </VCol>
                                    <VCol cols="12" sm class="message-text d-flex align-center ml-6" :class="messageText ? 'is-visible' : ''">
                                        <span :class="`text-${color}`" v-text="messageText"></span>
                                    </VCol>
                                </VRow>
                            </VCol>
                        </VRow>
                    </VContainer>
                </VForm>
            </ClientOnly>
        </VSheet>
        <Svg class="decorator-2 move-horizontal" name="decorator-2" width="clamp(9rem, 20vw,22rem)"></Svg>
        <Svg class="circle-decorator rotate" name="circle-decorator" width="clamp(5.6rem,12vw,11.5rem)" height="clamp(5.6rem,12vw,11.5rem)"></Svg>
    </div>
</template>

<style lang="scss" scoped>
.message-text {
    opacity: 1;
    transition: 0.25s opacity ease-in-out;

    &.is-visible {
        opacity: 1;
    }
}

.form-wrapper {
    position: relative;

    & > div {
        z-index: 1;
        position: relative;
    }

    & > svg {
        position: absolute;
        z-index: 0;
    }

    .decorator-2 {
        top: 0;
        right: -10%;
        transform: translate(0, -70%);
    }

    .circle-decorator {
        bottom: 0;
        left: 0;
        translate: -50% 50%;
    }
}
</style>
